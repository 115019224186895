import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import {
  getCurrencyFormat,
  getDateFormat,
} from '../../../utils/format/dataFormat';
import { getPaymentConceptKey } from '../BillingHistory/BillingHistory';
import Separator from '../../atoms/Separator/Separator';

const SCPaymentDetails = styled.div`
  .payments-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .payment-detail {
      display: flex;
      gap: 10px;
      justify-content: space-between;
    }

    .separator-container {
      margin: 5px 0px;
    }
  }
`;

const PaymentDetails = ({ payment }) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const {
    malwareProtection = 0,
    webProtection = 0,
    emailProtection = 0,
  } = payment.licenses ?? {};

  const paymentDetails = [
    {
      title: i18n.t('profile.paymentDetails.concept'),
      value: i18n.t(getPaymentConceptKey(payment)),
    },
    {
      title: i18n.t('profile.billingHistory.malwareLicenses'),
      value: malwareProtection,
      isHidden: malwareProtection === 0,
    },
    {
      title: i18n.t('profile.billingHistory.webLicenses'),
      value: webProtection,
      isHidden: webProtection === 0,
    },
    {
      title: i18n.t('profile.billingHistory.mailboxLicenses'),
      value: emailProtection,
      isHidden: emailProtection === 0,
    },
    {
      isSeparator: true,
    },
    {
      title: i18n.t('profile.paymentDetails.collectionDate'),
      value: getDateFormat(payment.createdAt.toDate()),
    },
    {
      title: i18n.t('profile.paymentDetails.periodStart'),
      value: getDateFormat(payment.periodStart.toDate()),
    },
    {
      title: i18n.t('profile.paymentDetails.periodEnd'),
      value: getDateFormat(payment.periodEnd.toDate()),
    },
    {
      isSeparator: true,
    },
    {
      title: i18n.t('profile.paymentDetails.subtotal'),
      value: getCurrencyFormat(payment.subtotal),
    },
    {
      title: i18n.t('profile.paymentDetails.taxes', {
        percentage: payment.taxRate,
      }),
      value: getCurrencyFormat(payment.taxAmount),
    },
    {
      title: i18n.t('profile.paymentDetails.total'),
      value: getCurrencyFormat(payment.amount),
    },
  ];

  return (
    <ModalLayout>
      <SCPaymentDetails>
        <h1>{i18n.t('profile.paymentDetails.title')}</h1>

        <div className="modal-card-content">
          <div className="payments-container">
            {paymentDetails.map(({ title, value, isSeparator, isHidden }) => {
              if (isHidden) {
                return null;
              }

              if (isSeparator) {
                return (
                  <div className="separator-container">
                    <Separator />
                  </div>
                );
              }

              return (
                <div className="payment-detail">
                  <p>
                    <strong>{title}</strong>
                  </p>

                  <p>{value}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="modal-card-buttons">
          <Button
            size="full"
            color="bluishGrey"
            text={i18n.t('common.close')}
            onClick={() => dispatch(showPopUp(null))}
          />
        </div>
      </SCPaymentDetails>
    </ModalLayout>
  );
};

export default PaymentDetails;
